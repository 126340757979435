import React from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces, Trans } from "react-i18next";
import FormioUtils from 'formiojs/utils';
import { Component, FormRenderer, FormioRenderer } from '../../../internal';

import $ from 'jquery';
import DataUtil from '../../../../../Utils/DataUtil';
import ServiceUtil from '../../../../../Utils/ServiceUtil';
import UiUtil from '../../../../../Utils/UiUtil';

import moment from 'moment';
import AppStateManager from '../../../../../../AppStateManager';

class SAPFormClass extends Component {

  constructor(props) {
    super(props);

    if ((!this.isInitiate() && !this.isDraft()) || this.isDisplay()) {
      const formGroup = this.props.component?.elements?.[0]?.elements?.find(e => e.code == this.getFormGroup());
      formGroup.properties.cssClassName = 'd-none';
    }

    //
  };

  // config
  config = {
    attachmentsContainerKey: 'ATTACHMENT',
    commentsContainerKey: 'FC_COMMENTS',
    tasksContainerKey: 'FC_TASKS',
    commentsKey: 'HRASR_CURRENT_NOTE',
    previousCommentsKey: 'HRASR_PREVIOUS_NOTES'
  };

  componentDidMount() {
    AppStateManager.setForm(this);
    this.showInitialView();
  };

  componentWillUnmount() {
    AppStateManager.setForm(null);
  };

  /*init = () => {
    if(this.state.component?.data)
      this.setState({ data: this.state.component?.data }, this.showInitialView);
    else
      this.showInitialView();
  };*/

  isInitiate = () => {
    const properties = this.props.component?.elements?.[0]?.properties;
    const processReferenceNumber = parseFloat(properties?.ProcessReferenceNumber) || 0;
    const workitemId = parseFloat(properties?.WorkitemId) || 0;

    return processReferenceNumber == 0 && workitemId == 0;
  };

  isDraft = () => {
    const properties = this.props.component?.elements?.[0]?.properties;
    const processReferenceNumber = parseFloat(properties?.ProcessReferenceNumber) || 0;
    const workitemId = parseFloat(properties?.WorkitemId) || 0;

    return processReferenceNumber == 0 && workitemId != 0;
  };

  isDisplay = () => {
    const processEvent = this.props?.params?.ProcessEvent;

    return processEvent == 'DISPLAY';
  };

  isVisible = (onLoad, component, data) => {
    if (onLoad) {
      const logic = component?.logic?.find(l => l.actions?.[0]?.property.value == 'hidden')
      if (logic)
        return !FormioUtils.checkTrigger(component.component, logic.trigger, data, data, component.root._form, component) && component._visible;
      else
        return component._visible;
    }
    else
      return component._visible;
  };

  preOnChange = ({ data, params, component }) => {
    //console.log('SAPForm - preOnChange()', data, params, component);

    return params.ProcessEvent != 'ON_DISPLAY';
  };

  preEvent = ({ event, eventData, params, component, instance, formProperties, changedEditGridField }) => {
    //console.log('SAPForm - preEvent()', event, eventData, params, component, instance, formProperties, changedEditGridField);

    // set the event of the clicked button
    params.UserEvent = event;
    params.ProcessGuid = formProperties?.ProcessGuid;

    if (instance.type == 'editgrid' && instance.getComponent('ROW_CHANGED')?.length) {
      try {
        eventData[instance.key][changedEditGridField.instance.rowIndex].ROW_CHANGED = 'X';
      }
      catch (e) { }
    }

    this.cleanUpData(eventData);

    return true;
  };

  postEvent = ({ event, eventData, params, component, responseData }) => {
    //console.log('SAPForm - postEvent()', event, eventData, params, component, responseData);
    const element = component._element;
    const eventFields = element.properties.eventFields;
    eventFields?.map(f => {
      if (responseData.metadata.fields[f]) {
        if (responseData.metadata.fields[f].readonly)
          this.formioFormRef.disableComponent(f);
        else
          this.formioFormRef.enableComponent(f);
      }
      else
        console.log(`Field ${f} not found in ${event}`);
    });
  };

  preButtonValidationEvent = ({ event, formValid, params }) => {
    // if draft or cancel or view history or edit button is clicked, we want to skip validation
    if (this.getDraftButton() == event.component.key ||
      this.getDeleteDraftButton() == event.component.key ||
      this.getCancelButton() == event.component.key ||
      this.getViewHistoryButton() == event.component.key ||
      this.getEditButton() == event.component.key ||
      (this.getSendButton() == event.component.key && params.ProcessEvent == 'ON_DELETE'))
      return true;
    else
      return formValid;
  };

  preButtonEvent = (event, data, params, formProperties, url) => {
    //console.log('SAPForm - preButtonEvent()', event, data, params, formProperties, url);
    const formio = this.formioFormRef.getFormio().formio;
    const componentKey = event.component.key;

    if (this.isInfoTypeForm()) {
      // for IT forms, if we are clicking the view history button or the edit button or the cancel button, 
      // we should not make a service call
      if (componentKey == this.getEditButton() || componentKey == this.getViewHistoryButton() || componentKey == this.getCancelButton())
        return false;
    }
    else {
      // if we are clicking the cancel button, we should not make a service call
      if (this.canReview() && componentKey == this.getCancelButton())
        return false;
    }

    // set the event of the clicked button
    params.UserEvent = event.type;
    params.ProcessGuid = formProperties?.ProcessGuid || '';

    if (componentKey === this.getEditButton()) {
      for (var key in data) delete data[key];
      url.url += "?v=false";
    }
    else
      this.cleanUpData(data);

    // set comments from the review screen to the form
    if (componentKey == this.getEditButton())
      formio.getComponent(this.config.commentsKey).setValue($('#' + this.getCommentsId()).val());
    // set comments from the form to the review screen
    else if (componentKey != this.getDraftButton() && componentKey != this.getReviewButton() && componentKey != this.getCancelButton())
      data[this.config.commentsKey] = $('#' + this.getCommentsId()).val();

    return true;
  };

  postButtonEvent = (event, data, params, responseData) => {
    //console.log('SAPForm - postButtonEvent()', event, data, params, responseData);
    const componentKey = event.component.key;
    if (this.isInfoTypeForm()) {
      if (componentKey == this.getEditButton())
        this.onEditClick(event.component, data, params, event);
      else if (componentKey == this.getSendButton())
        this.showInfoTypeConfirmation(event.component, data, params, event, responseData);
      else if (componentKey == this.getCancelButton())
        this.cancelInfoTypeForm(event.component, data, params, event);
      else if (componentKey == this.getViewHistoryButton())
        this.onViewAll(event.component, data, params, event);
    }
    else {
      if (componentKey == this.getDraftButton())
        this.showDraft(responseData);
      else if (componentKey == this.getSendButton())
        this.showConfirmation(responseData);
      else if (componentKey == this.getCancelButton()) {
        if (this.isCorrectionForm())
          this.cancelInfoTypeForm(event.component, data, params, event);
        else
          this.cancelForm();
      }
      else if (this.canReview() && (componentKey == this.getReviewButton() || componentKey == this.getEditButton())) {
        if (componentKey == this.getReviewButton())
          this.showReview({ onLoad: false, event, data, params, responseData });
        else if (componentKey == this.getEditButton())
          this.showForm();
        // update buttons visibility and 
        if (responseData) {
          const formio = this.formioFormRef.getFormio().formio;
          const buttons = formio.getComponents()[0].components.find(c => c.id == this.getButtonGroup()).getComponents()[0].getComponents();

          buttons?.map((button) => {
            const key = button.key;
            if (responseData.metadata.fields[key].hidden)
              this.formioFormRef.hideComponent(key);
            else
              this.formioFormRef.showComponent(key);
          });
        }
      }
      else
        this.showMessageAndExit(responseData, this.showInbox);
    }
  };

  cleanUpData = (data) => {
    const formio = this.formioFormRef.getFormio().formio;
    const componentsMap = FormioUtils.flattenComponents(formio.components);

    Object.keys(componentsMap).forEach(function (key) {
      const c = componentsMap[key];
      if (c.type == 'editgrid' && c.editRows?.length) {
        //console.log('editgrid: ', key, c, data[key]);
        if(c.cancelRowElements?.length) {
          //console.log('closing edit grid: ', key);
          c.cancelRowElements[0]?.click();
        }
        const cdata = [];
        c.editRows?.map(r => {
          const rdata = DataUtil.clone({ ...r.data });
          c.components?.map(ic => {
            this.cleanUpDataInner(ic, ic.component.key, rdata);
            //console.log('after cleanUpDataInner: ', ic.component.key, rdata);
          });
          cdata.push(rdata);
        });
        //console.log('editgrid after cleanup: ', key, c, data[key], cdata);
        data[key] = cdata;
      }
      else if (!c.inEditGrid)
        this.cleanUpDataInner(c, key, data)
    }.bind(this));
  };

  cleanUpDataInner = (c, key, data) => {
    if (data[key] != null) {
      // if component is a checkbox, we need to change true/false to 'X' and ''
      if (c.type == 'checkbox')
        data[key] = data[key] == true || data[key] == 'X' ? 'X' : '';
      else if (c.type == 'radio') { // when radio is required but initial value comes back as null, formio sets it as "Null" and validation pass when it should not - this check insures that the radio value is one of the available options, otherwise it passes null to the backend
        const values = c?.component?.values || c?.component?._element?.properties?.values || [];
        if (!values.find(e => data[key] == e.value))
          data[key] = null;
      }
      else if (c.type == 'number' && data[key] === '') 
        // if we clear a number component, it is not part of the data object however the back-end needs to get it as null 
        // 2023/05/26 - RJ: if we just do "(c.type == 'number' && data[key] == '')", and if data[key] = 0, this check will result as true, so changed check to "(c.type == 'number' && data[key] === '')")
        data[key] = null;
      else if (c.type == 'datetime') {
        if(data[key] == 'Invalid date')
          data[key] = '';
        else if(data[key] != '')
          data[key] = moment(data[key]).format(process.env.REACT_APP_SERVICE_DATE_FORMAT.toUpperCase());
      }
      // if select component and has an associated text field, we need to set it
      // TODO
    }
    else if (c.type == 'number') // if we clear a number component, it is not part of the data object however the back-end needs to get it as null
      data[key] = null;
  };

  isInfoTypeForm = () => {
    return this.props.component.properties?.Process?.includes('_INFTY') && !this.props.component.properties?.Process?.includes('_CORRECT');
  };

  isCorrectionForm = () => {
    return this.props.component.properties?.Process?.includes('_CORRECT');
  };

  canReview = () => {
    return this.props.component?.properties?.canReview == true;
  };

  getFormGroup = () => {
    return this.props.component?.properties?.formGroup || 'GRP_FORM';
  };

  getButtonGroup = () => {
    return this.props.component?.properties?.buttonGroup || 'GRP_BUTTON';
  };

  getReviewGroup = () => {
    return this.props.component?.properties?.reviewGroup//+'Cntr';
  };

  getDraftButton = () => {
    return this.props.component?.properties?.draftButton || 'BTN_SAVE_DRAFT';
  };

  getDeleteDraftButton = () => {
    return this.props.component?.properties?.deleteDraftButton || 'BTN_DELETE_DRAFT';
  };

  getReviewButton = () => {
    return this.props.component?.properties?.reviewButton || 'BTN_CHECK';
  };

  getSendButton = () => {
    return this.props.component?.properties?.sendButton || 'BTN_SEND';
  };

  getEditButton = () => {
    return this.props.component?.properties?.editButton || 'BTN_EDIT';
  };

  getCancelButton = () => {
    return this.props.component?.properties?.cancelButton || 'BTN_CANCEL';
  };

  getViewHistoryButton = () => {
    return this.props.component?.properties?.viewHistoryButton || 'BTN_VIEWHIST';
  };

  showInitialView = () => {
    //console.log('>>>>>> >>>>>> showInitialView', this.props.component);
    // add logic to show review if form is submitted OR any other condition
    if ((!this.isInitiate() && !this.isDraft()) || this.isDisplay()) {
      //console.log('>>>>>> showInitialView -> showReview');

      const formGroup = $('#' + this.getFormGroup());
      formGroup.addClass('d-none');

      //hide affix and show review 100%
      //formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').addClass('col-12');
      formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').removeClass('col-md-9');

      //formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').addClass('d-none');
      formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').removeClass('d-md-block');

      $('#' + this.getButtonGroup()).addClass('d-none');
      $('#' + this.getReviewGroup()).removeClass('d-none');

      this.formioFormRef.getFormio().createPromise.then((f) => {
        f.formReady.then(async () => {
          await this.renderReview(true);
          $('#' + this.getButtonGroup()).removeClass('d-none');
        })
      });
    }
  };

  showForm = () => {
    //console.log('>>>>>> showForm');

    const formGroup = $('#' + this.getFormGroup());
    formGroup.removeClass('d-none');
    //reset back to 9 and 3
    //formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').removeClass('col-12');
    formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').addClass('col-md-9');

    //formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').removeClass('d-none');
    formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').addClass('d-md-block');

    $('#' + this.getReviewGroup()).addClass('d-none');
  };

  showReview = ({ onLoad, event, data, params, responseData }) => {
    //console.log('>>>>>> showReview', onLoad);

    const formGroup = $('#' + this.getFormGroup());
    formGroup.addClass('d-none');
    //hide affix and show review 100%
    //formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').addClass('col-12');
    formGroup.parents('.form-renderer-row').find('.form-renderer-col-form').removeClass('col-md-9');

    //formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').addClass('d-none');
    formGroup.parents('.form-renderer-row').find('.form-renderer-col-affix').removeClass('d-md-block');

    $('#' + this.getReviewGroup()).removeClass('d-none');

    if (!onLoad)
      this.scrollToTop();

    this.renderReview(onLoad, responseData);
  };

  loadEditForm = (event, data, params, responseData) => {
    this.setState({ params: { ...params, ProcessEvent: 'ON_EDIT' } });
  };

  showDraft = (responseData) => {
    const messages = responseData.messages.map(m => { return m.message });
    const { t } = this.props;
    if (ServiceUtil.hasSuccess(responseData) || ServiceUtil.hasInfo(responseData))
      UiUtil.showAlert({ icon: 'success', title: t("draftSavedTitle"), text: messages.join('\n'), buttons: { leave: { text: t("exit"), value: true, visible: true, closeModal: true, className: 'swal-button--cancel' } } }, this.showInbox);
    else
      UiUtil.showAlert({ title: t("draftFailedTitle"), text: messages.join('\n') });
  };

  showInbox = () => {
    const url = '/hrd/inbox';
    this.props.history.push(url);
  };

  showConfirmation = (responseData) => {
    const url = '/hrd/confirmation?referenceNumber=' + responseData?.response?.d?.ProcessReferenceNumber;
    this.props.history.push(url);
  };

  showInfoTypeConfirmation = (component, data, params, event, responseData) => {
    //console.log('showInfoTypeConfirmation', responseData);
    this.showMessageAndExit(responseData, () => this.onViewAll(component, data, params, event));
  };

  showMessageAndExit = (responseData, exit) => {
    const messages = responseData.messages.map(m => { return m.message });
    const { t } = this.props;
    UiUtil.showAlert({ icon: 'success', title: t("savedTitle"), text: messages.join('\n'), buttons: { leave: { text: t("ok"), value: true, visible: true, closeModal: true, className: 'swal-button--cancel' } } }, exit);
  };

  renderReview = async (onLoad, responseData) => {
    try {
      const reviewGroup = $('#' + this.getReviewGroup());
      reviewGroup.html('<div class="page-loader text-center mb-4"><em class="fas fa-circle-notch fa-spin fa-2x"></em></div>');
      reviewGroup.html(await this.buildFormReview(onLoad, responseData));
      reviewGroup.find('a[rel="download-file"]').off('click').on('click', this.downloadFile);
    }
    catch (e) {
      console.log('Failed to generate Review view.', e);
    }
  };

  downloadFile = async (e) => {
    const componentKey = $(e.target).data('ckey');
    const fileIndex = $(e.target).data('file-index');
    const formio = this.formioFormRef.getFormio().formio;
    const component = formio.getComponent(componentKey);
    const files = await component.getFiles();
    const file = files?.[fileIndex];

    component.downloadFile(file);
  };

  getSubmission = (onLoad) => {
    return {
      data: {
        data: this.formioFormRef.getFormio().formio._data,
        initialData: this.props.component.data.data
      }
    }
  };

  getHideGroupComponentByKey = (key) => {
    const formio = this.formioFormRef.getFormio().instance.instance;
    return formio.getComponent(key)
  };

  buildFormReview = async (onLoad, responseData) => {
    const formio = this.formioFormRef.getFormio().instance.instance;
    const formSchema = formio.getComponents()[0].components.find(c => c.id == this.getFormGroup());
    const data = onLoad ? this.props.data.data : formio._data;

    return await this.buildFormReviewGroup(onLoad, formSchema.getComponents(), data, responseData);
  };

  buildFormReviewGroup = async (onLoad, components, data, responseData) => {
    const html = [];
    const showCurrent = true;
    // in the old HRD, the proposed column was conditionally hidden for some sections (based on a class name)
    // for now, we always show the proposed column
    const showProposed = true;
    const properties = this.props.component?.elements?.[0]?.properties;
    //const asOfDate = this.isInitiate() ? DataUtil.getDate(new Date(), DataUtil.getUserFormat().date) : DataUtil.getDate(properties.AsofDate);
    const asOfDate = responseData?.metadata?.form?.asofDate ? DataUtil.getDate(responseData.metadata.form.asofDate) : DataUtil.getDate(properties.AsofDate);
    const { t } = this.props;

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const componentDef = component.component;

      /*if(!component._visible)
        continue;*/
      if (!this.isVisible(onLoad, component, data))
        continue;

      //if(component.type == 'components' && component.component?.type != 'components')
      //  component = component.getComponents();

      if (componentDef.type == 'fieldset') {
        html.push('<div class="card border-0">');
        if (!this.isCorrectionForm()) {
          html.push('	<div class="card-header bg-transparent pl-0 pb-0">');
          html.push('		<h4 class="card-title text-100 text-review pt-1">' + componentDef.legend + '</h4>');
          html.push('		<div class="card-toolbar no-border"><a href="#" data-action="toggle" class="card-toolbar-btn btn btn-sm radius-1 btn-outline-default btn-h-outline-default px-15 mx-0 btn-tp"><i class="fa fa-chevron-up w-2 mx-1px"></i></a></div>');
          html.push('	</div>');
        }
        html.push('	<div class="card-body bg-white border-0">');
        html.push(`	  <table width="100%" cellpadding="3" data-ckey="${component.key}">`);
        html.push('	    <colgroup>');
        if (showCurrent && showProposed) {
          html.push('	      <col width="50%" />');
          html.push('	      <col width="50%" />');
        }
        else
          html.push('	      <col width="100%" />');
        html.push('	    </colgroup>');
        if (component.id != this.config.commentsContainerKey && component.id != this.config.attachmentsContainerKey && component.id != this.config.tasksContainerKey) { // using component.id instead of component.key since fieldets won't have a key
          html.push('	    <thead>');
          html.push('	      <tr>');
          if (showCurrent) {
            html.push('	      <th valign="top">');
            if (component.components && this.hasAnyCurrentField(onLoad, component, data))
              html.push(`	        <h5>${t("reviewCurrentTitle", {asOfDate})}</h5>`);
            html.push('	      </th>');
          }
          if (showProposed)
            html.push(`       <th valign="top"><h5>${t("reviewProposedTitle")}</h5></th>`);
          html.push('	      </tr>');
          html.push('	    </thead>');
        }
        html.push('	    <tbody>');
        if (component.components) {
          for (var j = 0; j < component.getComponents().length; j++) {
            const currentComponent = component.getComponents()[j];
            const isWrapperCol = currentComponent.component.type == 'columns' && currentComponent.component._type == 'dateWrapper';
            const childComponent = isWrapperCol ? currentComponent.columns[0][0] : currentComponent;
            /*if(!childComponent._visible || !childComponent.isInputComponent)
              continue;*/
            if (!this.isVisible(onLoad, currentComponent, data) || !this.isVisible(onLoad, childComponent, data) || !childComponent.isInputComponent)
              continue;
            html.push(await this.buildFormReviewComponents(onLoad, childComponent, data, showCurrent, showProposed));
          }
        }
        html.push('	    </tbody>');
        html.push('	  </table>');
        html.push('	</div>');
        html.push('</div>');
      }
    }

    return html.join('');
  };

  buildFormReviewComponents = async (onLoad, component, data, showCurrent, showProposed) => {
    const componentDef = component.component;
    const html = [];

    if (componentDef.type == 'editgrid')
      html.push(this.buildFormReviewRowForEditGrid({ onLoad, component, data, showCurrent, showProposed }));
    else if (component.key == this.config.commentsKey || component.key == this.config.previousCommentsKey)
      html.push(this.buildFormReviewRowForComments({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'textfield' || componentDef.type == 'textarea' || componentDef.type == 'email')
      html.push(this.buildFormReviewRowForText({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'number')
      html.push(this.buildFormReviewRowForNumber({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'currency')
      html.push(this.buildFormReviewRowForCurrency({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'checkbox')
      html.push(this.buildFormReviewRowForCheckbox({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'radio')
      html.push(this.buildFormReviewRowForRadio({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'select')
      html.push(this.buildFormReviewRowForSelect({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'datetime')
      html.push(this.buildFormReviewRowForDate({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'searchPopupCustomComp')
      html.push(this.buildFormReviewRowForSearch({ onLoad, component, data, showCurrent, showProposed }));
    else if (componentDef.type == 'fileUploadCustomComp')
      html.push(await this.buildFormReviewRowForFile({ onLoad, component, data, showCurrent, showProposed }));

    return html.join('');
  };

  hasAnyCurrentField = (onLoad, component, data) => {
    for (var j = 0; j < component.getComponents().length; j++) {
      const currentComponent = component.getComponents()[j];
      const isWrapperCol = currentComponent.component.type == 'columns' && currentComponent.component._type == 'dateWrapper';
      const childComponent = isWrapperCol ? currentComponent.columns[0][0] : currentComponent;
      const componentDef = childComponent.component;
      /*if(!childComponent._visible || !childComponent.isInputComponent)
        continue;*/
      if (!this.isVisible(onLoad, currentComponent, data) || !this.isVisible(onLoad, childComponent, data) || !childComponent.isInputComponent)
        continue;
      if (componentDef._element.properties?.currentField)
        return true;
    }
    return false;
  };

  buildFormReviewRowForEditGrid = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const formio = this.formioFormRef.getFormio().formio;
    const componentDef = component.component;
    const components = component.component.components?.filter(c => c.type != 'hidden');
    const html = [];
    const visibleMap = {};
    const { t } = this.props;

    components.map(currentComponent => {
      const isWrapperCol = currentComponent.type == 'columns' && currentComponent._type == 'dateWrapper';
      const fComponent = isWrapperCol ? formio.getComponent(currentComponent._key) : formio.getComponent(currentComponent.key);
      visibleMap[fComponent.key] = isWrapperCol ? this.isVisible(onLoad, fComponent.parent, data) : this.isVisible(onLoad, fComponent, data);
    });
    //console.log('visibleMap', visibleMap)

    //html.push(` <tr data-ckey="${component.key}">`);
    if (showCurrent) {
      html.push(` <tr data-ckey="${component.key}_current" data-ctype="${component.type}">`);

      const element = componentDef._element;
      html.push('   <td colspan="2" class="align-top">');
      if (element.properties?.currentField) {
        const rows = data[element.properties?.currentField];
        html.push(`   <h5>${t("reviewCurrentSubTitle")}</h5>`);
        html.push('   <table class="review-grid-view">');
        html.push('     <thead>');
        html.push('       <tr>');
        components.map(currentComponent => {
          const isWrapperCol = currentComponent.type == 'columns' && currentComponent._type == 'dateWrapper';
          const childComponent = isWrapperCol ? currentComponent.columns[0].components[0] : currentComponent;
          if(visibleMap[childComponent.key])
            html.push('       <th class="review-item-label">' + childComponent.label + '<th>');
        });
        html.push('       </tr>');
        html.push('     </thead>');
        html.push('     <tbody>');
        rows?.map((r, i) => {
          html.push('     <tr>');
          components?.map(currentComponent => {
            const isWrapperCol = currentComponent.type == 'columns' && currentComponent._type == 'dateWrapper';
            const childComponent = isWrapperCol ? currentComponent.columns[0].components[0] : currentComponent;
            if(visibleMap[childComponent.key]) {            
              if (childComponent.type == 'select')
                html.push('     <td>' + r[childComponent._element.properties?.currentField] + '<td>');
              else
                html.push('     <td>' + formio.getComponent(childComponent.key).getView(r[childComponent._element.properties?.currentField]) + '<td>');
            }
          });
          html.push('     </tr>');
        });
        html.push('     </tbody>');
        html.push('   </table>');
      }
      html.push('   </td>');

      html.push(` </tr>`);

    }
    if (showProposed) {
      html.push(` <tr data-ckey="${component.key}_proposed" data-ctype="${component.type}">`);

      const rows = component.editRows;
      html.push(' <td colspan="2" class="align-top">');
      html.push(`   <h5>${t("reviewProposedSubTitle")}</h5>`);
      html.push('   <table class="review-grid-view">');
      html.push('     <thead>');
      html.push('       <tr>');
      components.map(currentComponent => {
        const isWrapperCol = currentComponent.type == 'columns' && currentComponent._type == 'dateWrapper';
        const childComponent = isWrapperCol ? currentComponent.columns[0].components[0] : currentComponent;
        if(visibleMap[childComponent.key])
          html.push('       <th class="review-item-label">' + childComponent.label + '<th>');
      });
      html.push('       </tr>');
      html.push('     </thead>');
      html.push('     <tbody>');
      rows?.map(r => {
        html.push('     <tr>');
        r?.components?.map(currentComponent => {
          const isWrapperCol = currentComponent.component.type == 'columns' && currentComponent.component._type == 'dateWrapper';
          const childComponent = isWrapperCol ? currentComponent.columns[0][0] : currentComponent;
          if(visibleMap[childComponent.key])
            html.push('     <td>' + childComponent.getView(r.data[childComponent.key]) + '<td>');
        });
        html.push('     </tr>');
      });
      html.push('     </tbody>');
      html.push('   </table>');
      html.push(' </td>');
      html.push(' </tr>');

    }

    return html.join('');
  };

  getCommentsId = () => {
    const reviewGroup = this.getReviewGroup();
    return `${reviewGroup}-${this.config.commentsKey}`;
  };

  buildFormReviewRowForComments = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const html = [];

    if (component.key == this.config.commentsKey) {
      const commentsId = this.getCommentsId();
      const value = data[componentDef.key] || '';
      html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
      html.push('   <td colspan="2">');
      html.push('     <div class="form-group formio-component formio-component-textarea review-item">');
      html.push(`       <div class="review-item-label">${componentDef.label}</div>`);
      html.push('       <div ref="element">');
      html.push(`         <textarea id="${commentsId}" rows="3" spellcheck="true" lang="en" class="form-control" type="text" ref="input">${value}</textarea>`);
      html.push('       </div>');
      html.push('     </div>');
      html.push('   </td>');
      html.push(' </tr>');
    }
    else if (component.key == this.config.previousCommentsKey) {
      html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
      if (showProposed) {
        const value = data[componentDef.key] || '-';
        html.push(' <td colspan="2">');
        html.push('   <div class="review-item">');
        html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	    <div class="white-space-pre-wrap">' + value + '</div>');
        html.push('   </div>');
        html.push(' </td>');
      }
      html.push(' </tr>');
    }

    return html.join('');
  };

  buildFormReviewRowForText = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = data[element.properties?.currentField] || '-';
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + value + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = data[element.properties?.currentField] || '-';
      const value = data[componentDef.key] || '-';
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForNumber = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const componentCurrentDef = this.getHideGroupComponentByKey(element.properties.currentField)?.component;
        const value = DataUtil.convertNumber(data[element.properties?.currentField] != null ? data[element.properties?.currentField] : 0, (componentCurrentDef?.decimalLimit != null) ? componentCurrentDef.decimalLimit : componentDef.decimalLimit);
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	    <div>' + value + ' ' + (componentCurrentDef?.suffix || componentDef?.suffix) + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const componentCurrentDef = this.getHideGroupComponentByKey(element.properties.currentField)?.component;
      const currentValue = DataUtil.convertNumber(data[element.properties?.currentField] != null ? data[element.properties?.currentField] : 0, (componentCurrentDef?.decimalLimit != null) ? componentCurrentDef.decimalLimit : componentDef.decimalLimit);
      const value = DataUtil.convertNumber(data[componentDef.key] != null ? data[componentDef.key] : 0, componentDef.decimalLimit);
      //const value = component.dataValue != null ? component.getView(component._data || component.dataValue) : '-';
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + ' ' + componentDef?.suffix + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForCurrency = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = DataUtil.convertNumber(data[element.properties?.currentField], componentDef.decimalLimit);
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	    <div>' + (value != null ? value + ' ' + componentDef?.suffix : '-') + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = DataUtil.convertNumber(data[element.properties?.currentField], componentDef.decimalLimit);
      const value = DataUtil.convertNumber(data[componentDef.key], componentDef.decimalLimit);
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + (value != null ? value + ' ' + componentDef?.suffix : '-') + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForCheckbox = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const dataValue = data[element.properties?.currentField];
        const value = dataValue != null ? component.getView(/*component._data || */dataValue) : '-';
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + value + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = data[element.properties?.currentField] || '-';
      const dataValue = onLoad ? data[componentDef.key] : component.dataValue;
      const value = dataValue != null ? component.getView(/*component._data || */dataValue) : '-';
      html.push(' <td>');
      if(this.checkboxValueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForRadio = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = data[element.properties?.currentField] || '-';
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + value + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = data[element.properties?.currentField] || '';
      const dataValue = onLoad ? data[componentDef.key] : component.dataValue;
      const value = dataValue != null ? component.getView(/*component._data || */dataValue) : '-';
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForSelect = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = data[element.properties?.currentField] || '-';
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + value + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = data[element.properties?.currentField] || '-';
      //const value = component.dataValue != null && component.dataValue != '' ? component.getView(/*component._data || */component.dataValue) : '-';
      const dataValue = onLoad ? data[componentDef.key] : component.dataValue;
      let value = '-';
      try {
        if (dataValue != null && dataValue != '')
          value = $(component.getView(/*component._data || */dataValue)).text();
      }
      catch (e) {
        value = dataValue;
      }
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForDate = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const format = element?.properties?.format || DataUtil.getUserFormat().date;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = data[element.properties?.currentField || componentDef.key];
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + (DataUtil.getDate(value, format) || '-') + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = DataUtil.getDate(data[element.properties?.currentField || componentDef.key], format) || '-';
      const value = DataUtil.getDate(data[componentDef.key], format) || '-';
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForSearch = ({ onLoad, component, data, showCurrent, showProposed }) => {
    const componentDef = component.component;
    const element = componentDef._element;
    const html = [];

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showCurrent) {
      html.push('   <td>');
      if (element.properties?.currentField) {
        const value = data[element.properties?.currentField] || '&nbsp;';
        html.push('     <div class="review-item">');
        html.push('	      <div class="review-item-label">' + componentDef.label + '</div>');
        html.push('	      <div>' + value + '</div>');
        html.push('     </div>');
      }
      html.push('   </td>');
    }
    if (showProposed) {
      const currentValue = data[element.properties?.currentField] || '';
      const value = component.getText('<br />');
      html.push(' <td>');
      if(this.valueHasChanged(element.properties?.currentField, currentValue, value))
        html.push(' <div class="review-item-changed">');
      else
        html.push(' <div class="review-item">');
      html.push('	    <div class="review-item-label">' + componentDef.label + '</div>');
      html.push('	    <div>' + value + '</div>');
      html.push('   </div>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  buildFormReviewRowForFile = async ({ onLoad, component, data, showCurrent, showProposed }) => {
    const html = [];
    const { t } = this.props;

    html.push(` <tr data-ckey="${component.key}" data-ctype="${component.type}">`);
    if (showProposed) {
      const files = (onLoad) ? await component.fetchFiles() : component.getFiles();
      html.push(' <td colspan="2">');
      html.push('   <table class="review-grid-view">');
      html.push('     <thead>');
      html.push('       <tr>');
      html.push('         <th class="review-item-label">File<th>');
      html.push('       </tr>');
      html.push('     </thead>');
      html.push('     <tbody>');
      files?.map((file, index) => {
        html.push('     <tr>');
        html.push(`       <td><a href="javascript:void(0)" class="decorate-link" rel="download-file" data-ckey="${component.key}" data-file-index="${index}">${file.AttachmentFileName}</a><td>`);
        html.push('     </tr>');
      });
      if (!files?.length) {
        html.push('     <tr>');
        html.push(`       <td>${t("reviewNoFiles")}<td>`);
        html.push('     </tr>');
      }
      html.push('     </tbody>');
      html.push('   </table>');
      html.push(' </td>');
    }
    html.push(' </tr>');

    return html.join('');
  };

  valueHasChanged = (currentField, currentValue, newValue) => {
    const currentValueCleaned = typeof(currentValue) == 'string' ? currentValue.trim() : currentValue;
    const newValueCleaned = typeof(newValue) == 'string' ? newValue.trim() : newValue;
    const changed = currentField && currentValueCleaned != newValueCleaned;
    return changed;
  };

  checkboxValueHasChanged = (currentField, currentValue, newValue) => {
    const checkedValues = [true, 'X', 'YES'];
    const currentValueChecked = checkedValues.includes(typeof(currentValue) == 'string' ? currentValue.toUpperCase() : currentValue);
    const newValueChecked = checkedValues.includes(typeof(newValue) == 'string' ? newValue.toUpperCase() : newValue);
    const changed = currentField && (currentValueChecked && !newValueChecked || !currentValueChecked && newValueChecked);
    return changed;
  };

  cancelForm = () => {
    const { t } = this.props;
    UiUtil.showAlert({
      icon: 'info',
      title: t("cancelTitle"),
      text: t("cancelDesc"),
      buttons: {
        stay: { text: t("continue"), value: false, visible: true, closeModal: true },
        leave: { text: t("exit"), value: true, visible: true, closeModal: true, className: 'swal-button--cancel' }
      }
    }, this.back);
  };

  back = (leave) => {
    if (leave == true)
      this.props.history.goBack();
  };

  cancelInfoTypeForm = (component, data, params, event) => {
    const { t } = this.props;
    UiUtil.showAlert({
      icon: 'info',
      title: t("cancelTitle"),
      text: t("cancelDesc"),
      buttons: {
        stay: { text: t("continue"), value: false, visible: true, closeModal: true },
        leave: { text: t("exit"), value: true, visible: true, closeModal: true, className: 'swal-button--cancel' }
      }
    }, (leave) => { this.onCancelClick(component, data, params, event, leave) });
  };

  onEditClick = (component, data, params, event) => {
    this.scrollToTop();
    return this.props.listeners.onEditClick(component, data, params, event);
  };

  onCancelClick = (component, data, params, event, leave) => {
    if (leave == true) {
      this.scrollToTop();
      if(this.props.listeners?.onCancelClick)
        return this.props.listeners.onCancelClick(component, data, params, this.isCorrectionForm(), event);
      else
        this.back(leave);
    }
  };

  onViewAll = (component, data, params, event) => {
    this.scrollToTop();
    return this.props.listeners.onViewAll(component, data, params, event);
  };

  scrollToTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 1000);
  };

  draw() {
    //console.log('draw()', this.props.params);
    if (this.props.formType == 'formio')
      return <FormioRenderer component={this.props.component} preOnChange={this.preOnChange} preEvent={this.preEvent} postEvent={this.postEvent} preButtonValidationEvent={this.preButtonValidationEvent} preButtonEvent={this.preButtonEvent} postButtonEvent={this.postButtonEvent} params={this.props.params} listeners={this.props.listeners} reviewContainer={<div className="review-group" id={this.getReviewGroup()}></div>} ref={ref => { this.formioFormRef = ref; }} />
    else
      return <FormRenderer component={this.props.component} preOnChange={this.preOnChange} preEvent={this.preEvent} postEvent={this.postEvent} preButtonValidationEvent={this.preButtonValidationEvent} preButtonEvent={this.preButtonEvent} postButtonEvent={this.postButtonEvent} params={this.props.params} listeners={this.props.listeners} reviewContainer={<div className="review-group" id={this.getReviewGroup()}></div>} />;
  };
};

export const SAPForm = withNamespaces("form")(withRouter(SAPFormClass));
